import React from "react"

import Layout from "../components/layout/layout"
import Seo from "../components/seo"

import { Link } from "gatsby"

import "./index.scss"

const NotFoundPage = () => (
	<Layout>
		<Seo title="404: Stránka nenalezena" />
			<div className="stk-container stk-container--top-margin">
				<h1 className="stk-h1">404: Stránka nenalezena</h1>
				<p>Bohužel, tato stránka není dostupná na&nbsp;webu STK&nbsp;Praha.</p>

				<Link className="stk-button stk-button--big mt-2 mb-5" to="/">
					Zpět na úvod
				</Link>
			</div>
	</Layout>
)

export default NotFoundPage
